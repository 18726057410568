import React from 'react';
import logo from './../static/logo-2.png';
import { footer } from './../text';


export default function Footer({lang}) {
  return (
      <div className="Footer" id="footer">
          <div className="Footer__top">
            <img src={logo} alt="" />
            <ul className="info">
              <p className="info__head">{footer[lang].heading}</p>
              <li className="info__item"><p className="label">{footer[lang].address}:</p> г. Астана, ул. Кабанбай батыра 53</li>
              <li className="info__item"><p className="label">{footer[lang].email}:</p> <a href="mailto:info@nfoundation.kz">info@nfoundation.kz</a></li>
            </ul>
          </div>
      </div>
  )
}
